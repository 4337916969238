import {Types} from "ably/promises";
import * as Ably from "ably/promises";
import {handleMessage, playBell, registerAudio, registerClient} from "./lib";

(async () => {
    const channel = await registerClient(handleMessage)


    document.addEventListener("keydown", (event) => {
        const {key} = event;
        registerAudio();
        if (key === "Escape") {
            channel.publish("buzzes", {type: "reset"});
            return
        }
        channel.publish("buzzes", {key, type: "local"});
    });

    document.addEventListener("touchstart", (event) => {
        registerAudio();
        channel.publish("buzzes", {type: "remote"});
    });
})();

export {};
